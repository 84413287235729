function sidebar() {
    if ($("#offcanvasWithBackdrop").hasClass('show')) {
        // $("#main").css('visibility', 'hidden');
    } else {
        // $("#main").css('visibility', 'visible');
    }
}

$('.btn-menu').click(function () {
    sidebar();
})

var mobileSidebar = document.getElementById('offcanvasWithBackdrop')
mobileSidebar.addEventListener('hidden.bs.offcanvas', function () {
    sidebar();
})
mobileSidebar.addEventListener('shown.bs.offcanvas', function () {
    sidebar();
})


$("#ajax_form").submit(function () {
    event.preventDefault();
    var destination = $(this).attr('action');
    $.ajax({
        type: "POST",
        url: destination,
        data: $(this).serialize(),
        success: function (response) {
            alert("Obrigado! Recebemos seu e-mail, entraremos em contato em breve!");
        },
        error: function (response) {
            alert("Ocorreu um erro ao enviar seu e-mail. Por favor, tente novamente.");
            console.log(response);
        }
    });
});

$("#ajax_form2").submit(function () {
    event.preventDefault();
    var destination = $(this).attr('action');
    $.ajax({
        type: "POST",
        url: destination,
        data: new FormData(this),
        success: function (response) {
            alert("Curriculo enviado com sucesso!");
        },
        error: function (response, responseText) {
            alert("Ocorreu um erro ao enviar seu e-mail. Por favor, tente novamente.");
            alert(responseText)
            console.log(response);
        },
        processData: false,
        contentType: false
    });
});

$("#newsletter_form").submit(function (e) {
    var postData = $(this).serializeArray();
    var formURL = $(this).attr("action");
    $.ajax({
        url: formURL,
        type: "POST",
        data: postData,
        success: function (data, textStatus, jqXHR) {
            alert("Obrigado!");
        },
        error: function (jqXHR, textStatus, errorThrown) {
            alert("Ocorreu um erro, tente novamente!");
        },
    });
    e.preventDefault();
});

$(".tel").mask("00 0000-0000");
$(".cel").mask("00 0 0000-0000");
$(".cep").mask("00000-000");
$(".cnpj").mask("00.000.000/0000-00");
$(".numeros").mask("0000000000");
